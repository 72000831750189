import React from 'react'

export const About = () => {
    return (
        <div className="text-center">
            <h1 className="text-5xl mt-9 uppercase">About </h1>
            <h1 className="text-3xl mt-9">This is about Components Section</h1>
            <p className="text-2xl">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum, inventore optio quia magnam mollitia molestias et accusamus odit, quod laboriosam libero modi corporis quos pariatur amet eaque magni. Excepturi, corrupti!</p>
        </div>
    )
}
