import React from 'react'

const Service = () => {
    return (
        <div className="text-center bg-gray-500 p-11">
            <h1 className="text-5xl mt-9 uppercase text-yellow-600">Service</h1>
            <h1 className="text-3xl mt-9 text-pink-50">This is service Components Section</h1>
            <p className="text-2xl">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum, inventore optio quia magnam mollitia molestias et accusamus odit, quod laboriosam libero modi corporis quos pariatur amet eaque magni. Excepturi, corrupti!</p>
            
        </div>
    )
}

export default Service
